import { render, staticRenderFns } from "./customerDetailInfo.vue?vue&type=template&id=8b06e7ec&scoped=true&"
import script from "./customerDetailInfo.vue?vue&type=script&lang=js&"
export * from "./customerDetailInfo.vue?vue&type=script&lang=js&"
import style0 from "./customerDetailInfo.vue?vue&type=style&index=0&id=8b06e7ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b06e7ec",
  null
  
)

export default component.exports